<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <page-header-wrapper :title='this.$route.meta.title'>
      <a-card :body-style="{padding: '24px 32px'}" :bordered='false'>
        <a-form-model ref='form' :model='form' :rules='rules' :labelCol='labelCol' :wrapperCol='wrapperCol'>
          <a-form-model-item label='权限名称' prop='name'>
            <a-input v-model='form.name' />
          </a-form-model-item>
          <a-form-model-item label='授权事项' prop='symbol'>
            <a-input v-model='form.symbol' />
          </a-form-model-item>
          <a-form-model-item label='是否开通后台权限' prop='type'>
            <a-radio-group v-model='form.type'>
              <a-radio :value='0'>不开通</a-radio>
              <a-radio :value='1'>开通</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label=' ' :colon="false" prop='status' v-if="form.type == 1">
            <a-tree
              v-model="targetKeys"
              :treeData="routerList"
              blockNode
              checkable
              defaultExpandAll
            />
          </a-form-model-item>
          <a-form-model-item :wrapper-col=' { lg: { span: 15 ,offset: 7}, sm: { span: 17 ,offset: 7} }'>
            <a-button @click='handleCancel'>
              取消
            </a-button>
            <a-divider type='vertical' />
            <a-button type='primary' @click='handleSubmit'>
              提交
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </a-card>
  </page-header-wrapper>
</template>

<script>
import {
  putInsidePosition,
  getInsidePosition
} from '@/api/inside'
import { addRouters } from '@/config/router.config'

export default {
  name: 'LimitPositionEdit',
  data() {
    return {
      labelCol: { lg: { span: 5 }, sm: { span: 7 } },
      wrapperCol: { lg: { span: 15 }, sm: { span: 17 } },
      addRouters: addRouters,
      routerList: [],
      targetKeys: [],
      form: {
        type: 0
      },
      rules: {
        name: [
          { required: true, message: '职位名称', trigger: 'change' },
        ],
        type: [
          { required: true },
        ]
      }
    }
  },
  computed: {
    // id() {
    //   return this.$route.query.id || ""
    // },
  },
  created() {
    this.id = this.$route.query.id || "";
    this.handledOne();
  },
  activated() {
    this.id = this.$route.query.id || "";
    this.handledOne();
    // debugger;
  },
  methods: {
    handleCancel(e) {
      this.$router.back()
    },
    handleInitial() {
      let routerList = [];
      if (this.addRouters) {
        let i = 0;
        for (const item of this.addRouters[0].children) {
          if (item.name == "Home" || item.name == "exception" || item.name == "account") {
            continue;
          }
          routerList.push({
            title: item.meta.title,
            key: item.name
          })
          if (item.children && item.children.length > 0) {
            for (const child of item.children) {
              if (child.hidden) {
                continue
              }
              if (!routerList[i].children) {
                routerList[i].children = [];
              }
              routerList[i].children.push({
                title: child.meta.title,
                key: child.name
              })
              const delKey = this.targetKeys.indexOf(item.name);
              if (delKey > -1 && this.targetKeys.indexOf(child.name) > -1) {
                this.targetKeys.splice(delKey, 1);
              }
            }
          }
          i++;
        }
      }
      this.routerList = routerList;
    },
    async handleSubmit(e) {
      e.preventDefault()
      this.$refs.form.validate((result) => {
        if (result) {
          if (this.form.type == 1) {
            if (this.targetKeys.length == 0) {
              this.$message.warning('至少选择一项菜单')
              return
            }
            const menus = JSON.parse(JSON.stringify(this.targetKeys))
            for (const item of this.addRouters[0].children) {
              if (item.children && item.children.find(child => this.targetKeys.indexOf(child.name) > -1)) {
                menus.push(item.name);
              }
            }
            this.form.menus = JSON.stringify(menus);
          }
          putInsidePosition(this.form).then(res => {
            this.$router.back()
          })
        }
      })
    },
    onChange(targetKeys) {
      this.targetKeys = targetKeys;
    },
    onChecked(_, e, checkedKeys, itemSelect) {
      console.log(checkedKeys)
      const { eventKey } = e.node;
      itemSelect(eventKey, !(checkedKeys.indexOf(eventKey) !== -1));
      let data = this.routerList.find(item => item.key == eventKey)
      if (data && data.children) {
        for (const item of data.children) {
          itemSelect(item.key, !(checkedKeys.indexOf(item.key) !== -1));
        }
      }
    },
    handledOne(){
      if (this.id) {
        this.form.id = this.id;
        getInsidePosition(this.$route.query).then(result => {
          this.form = Object.assign({}, this.form, result)
          if (result.menus) {
            this.targetKeys = JSON.parse(result.menus)
            this.form.type = 1
          }
          this.handleInitial()
        }).catch(() => {
          this.handleInitial()
        })
      } else {
        this.handleInitial()
      }
      this.$form.createForm(this)
    }
  }
}
</script>
<style scoped>
.tree-transfer .ant-transfer-list:first-child {
  width: 50%;
  flex: none;
}
</style>
